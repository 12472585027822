import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'

export interface OfficialLoginResponseData {
  /** 微信公众号登录二维码的URL */
  qrcode_url: string
  /** 用于检测用户是否已关注公众号 */
  identity: string
  /** 二维码有效时长,单位毫秒(超过时长不再轮询) */
  ttl_ms: number
}

export interface IsFollowRequestParams {
  /** 用于检测用户是否已关注公众号 */
  identity: string
}

export interface isFollowResponseData {
  /** 登录的token(未关注情况下token为空) */
  token: string
  /** 接口轮询时间间隔, 单位毫秒 */
  check_time_ms: number
}

@Injectable()
export class OfficialLoginService {
  constructor(private http: Http) {
  }

  /**
   * @description 请求公众号二维码
   */
  requestOfficialQrcode () {
    return this.http.post<OfficialLoginResponseData>('/account/wx/official_account/login_qrcode', {})
  }

  /**
   * @description 请求公众号是否关注
   */
  requestOfficialFollow (params: IsFollowRequestParams) {
    return this.http.post<isFollowResponseData>('/account/wx/official_account/is_follow', params)
  }
}
