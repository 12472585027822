
import {defineComponent, reactive, computed } from 'vue'
import {useReflectiveInjector} from '@tanbo/vue-di-plugin'
import { LoginService } from '@/views/login/login.service'
import { PhoneLoginService, err_ret_info } from './phone-login.service'
import { ToastService } from '@/services/toast.service';
import {login_type_info} from '@/services/config.service';

/** 手机登录 */
export default defineComponent({
  name: 'PhoneLogin',
  setup() {
    const injector = useReflectiveInjector([PhoneLoginService])
    const service = injector.get(PhoneLoginService)
    const login = injector.get(LoginService)
    const toast = injector.get(ToastService)

    const viewModel = reactive({
      isPhoneLogin: true, /** 是否为手机登录，用于区分手机登录|填写验证码的场景 */
      timer: null, /** 定时器标识 */
      count: -1, /** 验证码定时器计数，还用于控制文字，使用参考yzmText computed属性 */
      isSendyzm: false, /** 是否已发送验证码 */
      imgYzmUrl: '', /** 图形验证码地址 */
      form: {
        phone: '',
        yzm: '',
        imgYzm: '' /** 校验图形验证码文本 */
      },
      err: {
        phone: false,
        yzm: false,
        imgYzm: false
      }
    })

    /** 验证码可变状态文字 */
    const yzmText = computed(() => {
      const { count } = viewModel

      const yzmObj = {
        '-1': '获取验证码',
        '-2': '发送中...',
        '-3': '重发送验证码'
      }

      return yzmObj[count] ?? `${count}s后重试`
    })

    /**
     * @description 获取短信验证码
     */
    function gainMsgYzm () {
      if (!valid('phone') || viewModel.isSendyzm) return

      const params = {
        phone_number: viewModel.form.phone
      }

      viewModel.count = -2
      service.requestGetMsgYzm(params).then(() => {
        viewModel.isSendyzm = true
        srartCountDown()
      }).catch((err) => {
        const ret = err.responseCommon.ret

        // 需要通过图形验证码认证
        if (ret === err_ret_info.often) {
          toast.showToast({ message: '您的操作频率过快，请稍后重试' })
        } else if (ret === err_ret_info.max) {
          gainImgYzm()
          viewModel.isPhoneLogin = false
        }
      })
    }

    /**
     * @description 获取图形验证码
     */
    function gainImgYzm () {
      const params = {
        phone_number: viewModel.form.phone
      }

      service.requestGetImgYzm(params).then(res => (viewModel.imgYzmUrl = res.image_uri))
    }

    /**
     * @description 校验图形验证码
     */
    function checkImgYzm() {
      if (!valid('imgYzm')) {
        return
      }

      const params = {
        phone_number: viewModel.form.phone,
        answer: viewModel.form.imgYzm,
      }

      service.requestCheckImgYzm(params).then(() => {
        gainMsgYzm()
        viewModel.isPhoneLogin = true
        viewModel.form.imgYzm = ''
      }).catch(() => (viewModel.err.imgYzm = true))
    }

    /**
     * @description 开始倒计时
     */
    function srartCountDown () {
      let count = 60
      const wait = 1000

      viewModel.timer = setInterval(() => {
        if (count <= 0) {
          viewModel.isSendyzm = false
          viewModel.count = -3
          clearInterval(viewModel.timer)
          return
        }

        viewModel.count = count--
      }, wait)
    }

    /**
     * @description 校验验证码
     * @param type 类别
     */
    function valid (type: string): boolean {
      const regObj = {
        phone: /(?:^1[3456789]|^9[28])\d{9}$/,
        yzm: /^\d{6}$/,
        imgYzm: /^[0-9A-Za-z]{5}$/
      }

      const result = regObj[type].test(viewModel.form[type])
      viewModel.err[type] = !result

      return result
    }

    /**
     * @description 检测表单
     */
    function checkForm () {
      return valid('phone') && valid('yzm')
    }

    /**
     * @description 去登陆
     */
    function toLogin () {
      if (!checkForm()) {
        return
      }

      const params = {
        phone_number: viewModel.form.phone,
        code: viewModel.form.yzm,
        device_info: {
          account: '',
          os: ''
        }
      }

      service.requestPhoneLogin(params).then(res => {
        login.handleLoginSuccess(res, login_type_info.phone)
      }).catch(err => {
        login.handleLoginFail(err, login_type_info.phone)
      })
    }

    return {
      viewModel,
      yzmText,
      valid,
      toLogin,
      gainImgYzm,
      gainMsgYzm,
      checkImgYzm
    }
  }
})

