
import QrcodeVue from 'qrcode.vue'
import { defineComponent, onMounted, onBeforeUnmount, reactive } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { OfficialLoginService } from './official-login.service'
import {LoginService} from '../login.service'
import {login_type_info} from '@/services/config.service'

/** 公众号登录 */
export default defineComponent({
  name: 'OfficialLogin',
  components: {
    QrcodeVue
  },
  setup () {
    let isDestroy = false // 用于劫持异步
    const injector = useReflectiveInjector([OfficialLoginService])
    const service = injector.get(OfficialLoginService)
    const login = injector.get(LoginService)

    const viewModel = reactive({
      qrText: '',
      retry: false,
      identity: '',
      loopTimer: null,
      expiryTimer: null,
      loopFollowTime: 2000,
      quickLoginRes: null, // 快速登录返回结果
    })

    onMounted(() => loadLoginQrCode())

    onBeforeUnmount(() => {
      isDestroy = true
      clearExpiryTimer()
      clearLoopIsFollow()
    })

    /**
     * @description 加载登录二维码
     */
    function loadLoginQrCode () {
      service.requestOfficialQrcode().then(res => {
        if (isDestroy) {
          return
        }

        if (!res.qrcode_url) {
          viewModel.retry = true
          return
        }

        viewModel.retry = false
        viewModel.qrText = res.qrcode_url
        viewModel.identity = res.identity

        login.report({
          show_time: login.reportCurrentDate(),
          login_act: 1
        })

        loopIsFollow()

        expireReloadQrcode(res.ttl_ms)
      }).catch((err) => {
        qrRetry()
        login.report({
          show_time: login.reportCurrentDate(),
          login_act: 1,
          reason: JSON.stringify(err.responseCommon)
        })
      })
    }

    /**
     * @description 过期重新加载二维码
     */
    function expireReloadQrcode (wait: number) {
      clearExpiryTimer()
      viewModel.expiryTimer = setTimeout(() => qrRetry(), wait)

      // login.report({
      //   show_time: login.reportCurrentDate(),
      //   login_act: 1,
      //   reason: '二维码过期'
      // })
    }

    /**
     * @description 启动是否关注公众号轮询
     */
    function loopIsFollow () {
      clearLoopIsFollow()
      viewModel.loopTimer = setInterval(() => isFollowOfficial(), viewModel.loopFollowTime)
    }

    /**
     * @description 清除是否关注公众号定时器标识
     */
    function clearLoopIsFollow () {
      clearInterval(viewModel.loopTimer)
    }

    /**
     * @description 清除二维码过期定时器标识
     */
    function clearExpiryTimer () {
      clearTimeout(viewModel.expiryTimer)
    }

    /**
     * @description 二维码错误
     */
    function qrRetry () {
      viewModel.retry = true
      clearLoopIsFollow()
    }

    /**
     * 是否关注公众号
     */
    function isFollowOfficial () {
      const params = { identity: viewModel.identity }
      service.requestOfficialFollow(params).then(res => {
        if (res.token) {
          clearLoopIsFollow()
          fastLoginAction(res.token)
          return
        }

        viewModel.loopFollowTime = res.check_time_ms
        loopIsFollow()
      })
    }

    /**
     * @description 快速登录
     */
    function fastLoginAction (token: string) {
      login.requestQuickLogin(token, login_type_info.wechat)
    }

    return {
      viewModel,
      loadLoginQrCode
    }
  }
})

