import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'
import { CookieService } from '@/services/cookie.service'
import { BridgeService } from '@/services/bridge.service'
import { ReportService } from '@/services/report.service'
import {UserService} from '@/services/user.service';
import {CommonService} from '@/services/common.service';
import { ConfigService } from '@/services/config.service'
import { LocalStorageService } from '@/services/local-storage.service'

export interface FastLoginUserInfo {
  /** 用户id */
  open_id: string
  /** 用户姓名 */
  name: string
  /** 用户头像URL */
  avatar: string
  /** 用户性别, 0未知; 1男性; 2女性 */
  sex: number
  /** 登录类型 */
  login_type: number
}

export interface TokenInfo {
  /** token */
  token: string
  /** 下次刷新用户token的时间间隔,单位毫秒 */
  check_time_ms: number
}

export interface FastLoginResponseData {
  /** 用户信息 */
  user_info: FastLoginUserInfo
  /** 登录的token */
  token_info: TokenInfo
}

@Injectable()
export class LoginService {
  constructor(
    private http: Http,
    private bridge: BridgeService,
    private cookie: CookieService,
    private reporter: ReportService,
    private user: UserService,
    private common: CommonService,
    private localStorage: LocalStorageService,
    private config: ConfigService
  ) {
  }

  report = this.reporter.makeReporter('waitui_52haoya_login_info:361 show_from:string fun_from:string product_id:int version:short vip_version:short login_result:byte reason:string show_time:string login_type:short is_vip:byte login_act:short', {
    login_result: 0,
    reason: '',
    show_time: '',
    uuid: this.cookie.uuid,
    tryno: this.cookie.tryno,
    login_type: 0,
    is_vip: this.user.isVip ? 1 : 2,
    show_from: parseInt(this.localStorage.couponShowTimestamp) === -1 ? '21' : this.cookie.show_from,
    login_act: 0, /** 操作 1：展示 2：登录结果 */
    fun_from: this.cookie.fun_from,
  })

  /**
   * @description 请求快速登录
   */
  requestQuickLogin (token: string, loginType: number) {
    const params = {
      common: {
        token
      },
      device: { // TODO: 设备信息一期先不做，暂且穿空
        account: '',
        os: ''
      }
    }

    return this.http.post<FastLoginResponseData>('/account/login/token', params).then(res => {
      this.handleLoginSuccess(res, loginType)
    }).catch(err => {
      this.handleLoginFail(err, loginType)
    })
  }

  /**
   * @description 处理登录成功
   */
  handleLoginSuccess(res: FastLoginResponseData, loginType: number) {
    // 每次刷新页面客户端会根据token拉取用户信息登录状态并设置cookie，这里免设置cookie
    const userInfo = res.user_info
    const { token } = res.token_info
    const { open_id, login_type } = userInfo
    const clientData = { token, open_id, type: login_type }

    this.cookie.setUserInfoLongTerm({ token, open_id }) // 用户信息持久化
    this.cookie.addRecentLoginList({ ...userInfo, token }) // 历史登录持久化

    this.report({
      show_time: this.reportCurrentDate(),
      login_act: 2,
      login_type: loginType, /** 服务端返回缺少快速登录，故做兼容 */
      login_result: 1
    })

    this.afterFunc(clientData)
  }

  /**
   * @description 之后运行
   */
  afterFunc (data) {
    this.bridge.winClose(data)
    window.close()
  }

  /**
   * @description 处理活动领取
   */
  requestAcitivityReceive (params) {
    return this.http.post('/activity/receive', params)
  }

  /**
   * @description 处理登录失败
   */
  handleLoginFail (err: any, loginType: number) {
    this.report({
      show_time: this.reportCurrentDate(),
      login_act: 2,
      login_result: 2,
      login_type: loginType,
      reason: JSON.stringify(err.responseCommon)
    })
  }

  /** 上报当前时间 */
  reportCurrentDate() {
    return this.common.handleDate(new Date().getTime(), 'yyyy-MM-dd hh:mm:ss')
  }
}
