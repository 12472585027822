
import {defineComponent, reactive, onMounted} from 'vue'
import {useReflectiveInjector} from '@tanbo/vue-di-plugin'
import {CookieService} from '../../../services/cookie.service'
import { LoginService } from '../login.service'
import { FastLoginService } from './fast-login.service'
import { login_type_info, login_type_text_info } from '@/services/config.service';

/** 快速登录 */
export default defineComponent({
  name: 'FastLogin',
  emits: ['updateFastLogin'],
  setup(_, { emit }) {
    const injector = useReflectiveInjector([FastLoginService])
    const service = injector.get(FastLoginService)
    const cookie = injector.get(CookieService)
    const login = injector.get(LoginService)

    const viewModel = reactive({
      defaultAvatar: '', /** 默认头像 */
      fastAccountList: cookie.getRecentLoginList(), /** 快速登录账户列表 */
      loginTypeMap: login_type_text_info
    })

    onMounted(() => checkUserExpire())

    /**
     * @description 检查用户是否过期
     */
    function checkUserExpire () {
      if (viewModel.fastAccountList?.length === 0) {
        return
      }

      viewModel.fastAccountList.forEach(async (item, idx) => {
        await service.requestCheckUserExpire({
          common: {
            open_id: item.open_id,
            token: item.token
          }
        }).catch(() => removeFastAccountItem(idx))
      })

      if (viewModel.fastAccountList.length) {
        emit('updateFastLogin', true)
      }
    }

    /**
     * @description 删除快速登录账户
     */
    function removeFastAccountItem (index: number) {
      viewModel.fastAccountList.splice(index, 1)
      cookie.removeRecentLoginItem(index)

      if (viewModel.fastAccountList.length === 0) {
        emit('updateFastLogin', false)
      }
    }

    /**
     * @description 使用其他账号登录
     */
    function useOtherAccountLogin () {
      emit('updateFastLogin', false)
    }

    /**
     * @description 快速登录
     */
    function fastLoginAction (token: string) {
      login.requestQuickLogin(token, login_type_info.fast)
    }

    /** 错误使用默认头像 */
    function errorImg() {
      viewModel.defaultAvatar = require('@/assets/images/not-login-avatar.png')
    }

    return {
      viewModel,
      errorImg,
      fastLoginAction,
      useOtherAccountLogin,
      removeFastAccountItem,
    }
  }
})

