import {Injectable} from '@tanbo/vue-di-plugin'
import {Http} from '@zhfe/common-library'

export interface MsgYzmRequestParams {
  /** 手机号 */
  phone_number: string
}

export interface ImgYzmRequestParams {
  /** 手机号 */
  phone_number: string
}

export interface CheckImgYzmRequestParams {
  /** 手机号 */
  phone_number: string
  /** 验证码文本 */
  answer: string
}

export interface ImgYzmResponseData {
  /** 验证码地址，格式 "data:image/png;base64" */
  image_uri: string
}

export interface DeviceInfo {
  /** 设备账号 */
  account: string
  /** 系统 */
  os: string
}

export interface PhoneLoginRequestParams {
  /** 手机号 */
  phone_number: string
  /** 短信验证码 */
  code: string
  /** 设备信息 */
  device_info: DeviceInfo
}

export interface PhoneLoginUserInfo {
  /** 用户id */
  open_id: string
  /** 用户姓名 */
  name: string
  /** 用户头像URL */
  avatar: string
  /** 用户性别, 0未知; 1男性; 2女性 */
  sex: number
  /** 登录方式(1-手机, 2-微信, 3-qq) */
  login_type: number
}

export interface PhoneLoginTokenInfo {
  /** 登录的token */
  token: string
  /** 下次刷新用户token的时间间隔,单位毫秒 */
  check_time_ms: number
}

export interface PhoneLoginResponseData {
  /** 用户信息 */
  user_info: PhoneLoginUserInfo
  /** 用户token详情 */
  token_info: PhoneLoginTokenInfo
  /** 会员详情 */
  // vip_info: VipInfo
}

/** 发送验证码错误状态码 */
export enum err_ret_info {
  often = 4572002, /** 短信发送频繁 */
  max = 4572007 /** 超过最大发送次数 */
}

@Injectable()
export class PhoneLoginService {
  constructor(private http: Http) {
  }

  /**
   * @description 请求手机登录
   */
  requestPhoneLogin (params: PhoneLoginRequestParams) {
    return this.http.post<PhoneLoginResponseData>('/account/login/phone', params)
  }

  /**
   * @description 请求获取短信验证码
   */
  requestGetMsgYzm (params: MsgYzmRequestParams) {
    return this.http.post('/sms/send_code', params)
  }

  /**
   * @description 请求获取图形验证码
   */
  requestGetImgYzm (params: ImgYzmRequestParams) {
    return this.http.post<ImgYzmResponseData>('/sms/captcha', params)
  }

  /**
   * @description 请求获取图形验证码
   */
  requestCheckImgYzm (params: CheckImgYzmRequestParams) {
    return this.http.post('/sms/check_captcha', params)
  }
}
