import { Http } from '@zhfe/common-library'
import { Injectable } from '@tanbo/vue-di-plugin'

export interface TokenInfo {
  /** token */
  token: string
  /** 下次刷新用户token的时间间隔,单位毫秒 */
  check_time_ms: string
}

export interface ResponseData {
  /** 用户token详情 */
  token_info: TokenInfo
}

@Injectable()
export class FastLoginService {
  constructor(private http: Http) {}

  /**
   * @description 请求用户是否过期
   */
  requestCheckUserExpire(params) {
    return this.http.post<ResponseData>('/account/refresh_token', params)
  }
}
