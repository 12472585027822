
import { defineComponent, reactive} from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { LoginService } from './login.service'
import FastLogin from './_components/fast-login.vue'
import PhoneLogin from './_components/phone-login.vue'
import OfficiaLogin from './_components/official-login.vue'
import { ConfigService } from '@/services/config.service'

export default defineComponent({
  name: 'Login',
  components: {
    FastLogin,
    PhoneLogin,
    OfficiaLogin,
  },
  setup () {
    const injector = useReflectiveInjector([LoginService])
    const config = injector.get(ConfigService as Type<ConfigService>)

    const viewModel = reactive({
      logo: config.getLogo,
      title: config.getTitle,
      tabIdx: 0, // tab下标
      officialLoginImg: config.getOfficialLoginImg,
      protocolUrl: config.getMemberProtocolLink,
      showFastLogin: false, /** 是否显示快速登录 */
      tabs: ['微信登录', '手机登录']
    })

    /**
     * @description 切换tab
     */
    function toggleTab (idx: number) {
      if (viewModel.tabIdx === idx) {
        return
      }

      viewModel.tabIdx = idx
    }

    /**
     * @description 更新快速登录状态
     */
    function updateFastLogin(bool) {
      viewModel.showFastLogin = bool
    }

    function clickClose() {
      window.close()
    }

    /**
     * @description 打开会员协议
     */
    function openMemberProtocol () {
      window.open(viewModel.protocolUrl)
    }

    return {
      viewModel,
      toggleTab,
      clickClose,
      updateFastLogin,
      openMemberProtocol,
    }
  }
})
